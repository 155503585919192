
.container{
		background-color: #51464C;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 100%;
			// width: 100%;
			// max-width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		&::-webkit-scrollbar{
			height: 10px;
			background-color: #725f5a;
		}
		&::-webkit-scrollbar-thumb {
			box-shadow: inset 0 0 6px rgba(0,0,0,.3);
			background-color: #cf9482;
		}
		&.hasScrollBar,
		.hasScrollBar {
			justify-content: flex-start;
		}
		.adminText{
			color: white;
			font-size: 40px;
		}
	button{ 
		width: 8.5vmin !important;
		min-width: 8.5vmin;
		@media (-webkit-device-pixel-ratio: 1.25) {
			@media screen and (max-width: 1440px) {
				width: 8.5vmin !important;
				min-width: 8.5vmin;
			}
			@media screen and (max-width: 1100px) {
				width: 10.5vmin !important;
				min-width: 8.5vmin;
			}
		}
	}

	&.lessThanMax{
		@media (-webkit-device-pixel-ratio: 1.5) {
			@media screen and (max-width: 1920px) {
				button {
						width: 9vh !important;
						min-width: 9vh;
					}
			}
			@media screen and (max-width: 1280px) {
				// width: 20%;
			}
		}

		@media (-webkit-device-pixel-ratio: 1.25) {
			@media screen and (max-width: 1920px) {
				button {
						width: 9vh !important;
						min-width: 9vh;
					}
			}
			@media screen and (max-width: 1440px)  {
				button {
					width: 11vh !important;
					min-width: 10vh;
				}
			}


			@media screen and (max-width: 1280px) {
				button {
					width: 8.5vmin !important;
					min-width: 8.5vmin;
				}
			}

			@media screen and (max-width: 1100px) {
				button {
					width: 11vmin !important;
					min-width: 11vmin;
				}
			}

			@media screen and (max-width: 1024px) {
				button {
					width: 6.5vmin !important;
					min-width: 6.5vmin;
				}
			}
		}


		@media (-webkit-device-pixel-ratio: 1) {
			@media screen and (max-width: 1440px)  {
				button {
					width: 8vh !important;
					min-width: 8vh;
				}
			}

			@media screen and (max-width: 1280px) {
				button {
					width: 6vh !important;
					min-width: 6vh;
				}
			}

			@media screen and (max-width: 1100px) {
				button {
					width: 7vh !important;
					min-width: 7vh;
				}
			}

			@media screen and (max-width: 1023px) {
				button {
					width: 12vh !important;
					min-width: 8vh;
				}
			}
		}
	}

	&.Toolbox{
		padding-left: 0px;
		justify-content: flex-start;
		button{ 
			width: 8vh !important;
			min-width: 7vh;
			@media screen and (max-width: 1100px) {
				width: 10.5vmin !important;
				min-width: 8.5vmin;
			}
			@media screen and (max-width: 1023px) {
				width: 12.5vmin !important;
				min-width: 8.5vmin;
			}
		}
	}
}

// .dragable {
// 	button {
// 		width: 8vh;
// 		min-width: 6vh;
// 	}

// 	&.Toolbox{
// 		button {
// 			width: 7vh;
// 			min-width: 6vh;
// 		}
// 	}
// 	&.small{


// 	}

// }