@font-face { font-family: BopomofoRuby; src: url(./font/BopomofoRuby1909-v1-Regular.ttf);}

*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Arial, Microsoft JhengHei, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif,  Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  color: #000;
  width: 47px;
  height: 47px;
  outline: none;
}

#root {
  width: 100%;
  height: 100%;
  overflow: auto;
}
