.ok {
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
}
.title{
  text-align: center;
}
.wrapper {
  height: 100%;
}

.localVideoContainer {
  //height: 50%;
}

.fullStudentVideo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 100000;
  & > div {
    height: 100%;
    width: 100%;
    position: relative;
    background: #1b1c20;
    /* display: flex; */
    /* justify-content: center; */
    /* margin: auto; */
    padding: 20px;
    & > video {
      position: static;
    }
  }
}

.remoteVideoContainer {
  width: 50%;
  height: 100%;
  position: absolute;
  left: -50%;
  z-index: 10000;
  & > div {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: inline-block;
    & > div {
      max-width: 100%;
      video {
        position: initial;
      }
      & > div {
        left: 2.5rem;
        color: white;
        font-size: 0.3rem;
      }
    }
  }
}

.chatContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  .toolsControls {
    height: 20%;
    padding-top: 10px;
    .toolControl {
      display: flex;
    }
    .studentToolsControl {
      display: flex;
      button {
        width: 70px;
        height: 25px;
      }
    }

    .videoDeviceSelectComponent {
      width: 75%;
    }
  }

  .toolSwitch {
    // width: 80%;
    // height: 25%;
    // background: #49C0B8;
    // border-radius: 5px;
    // margin: 0 auto;
    // display: flex;
    // justify-content: center;
    // border: 3px #ffffff solid;
    // margin-bottom: 10px; */
    padding-left: 14%;
    cursor: pointer;
    & img {
      //width: 48%;
    }
    &.offTools {
      background: rgb(44, 44, 44);
    }
    & div {
      text-align: center;
      line-height: 150%;
    }
  }

  .shareButton{
    width: auto;
    outline: none;
    border: none;
    appearance: none;
    background: #49C0B8;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    height: 19px;
    border-radius: 4px;
    &:disabled{
      background-color: #ccc;
    }
  }

  .localVideoHide div video {
    display: none;
  }
  .fullVideoBtnHide {
    display: none;
  }
  .localVideo {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 20%;
    margin-bottom: 5px;

    background-color: none;

    & > div {
      //cursor: pointer;
      padding-right: 5px;
    }

    &.localVideoBG{
      background-color:black;
    }
    button {
      width: 25%;
      height: 25px;
    }
    .offFullVideoBtn {
      cursor: pointer;
      position: absolute;
      width: 60%;
      display: flex;
      justify-content: center;
      padding: 23%;
      &.offFullVideoBtnHide {
        display: none;
      }
      img {
        position: absolute;
      }
      img:last-child:hover {
        opacity: 0;
      }
    }
    .fullVideoBtn {
      cursor: pointer;
      position: absolute;
      width: 10%;
      margin: 10px;
      right: 0px;
      img {
        position: absolute;
      }
      img:last-child:hover {
        opacity: 0;
      }
    }
  }

  .localVideoSelect{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    & > div {
      //cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
  }

  .localAudio {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    & > div {
      //cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
  }

  .localSound {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    & > div {
      cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
  }

  .emojiCounts {
    position: relative;
    display: flex;
    min-height: 5%;
    background-color: black;

    .openTools {
      display: flex;
      margin: 5px;
      padding-top: 5px;
      padding-left: 5px;
      & > div {
        cursor: pointer;
        margin: 2px;
      }
    }
    .emojiCount {
      display: flex;
      width: 20%;
      margin: 5px;
      & > div {
        margin: 0 auto;
        padding-top: 4px;
      }
      .countText {
        margin: 2px;
      }
    }
  }

  .chatRoomWrapper {
    flex: 1;
    max-height: 35%;
    &.tutor {
      max-height: 35%;
    }
  }
  .teacherVideo {
    width: 75%;
    height: 20%;
    margin: 0 auto;
    .attendeeContent {
      position: relative;
      .volume {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }
  }
  .controls {
    height: 10%;
    .studentListbutton {
      width: 210px;
      height: 40px;
      left: 12px;
      top: 10px;
      background: #006db2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      text-align: center;
      margin: 0 auto;
      line-height: 40px;
    }
  }
}

.listContent {
  width: 250px;
  height: 50vh;
  overflow-y: hidden;

  .title {
    display: flex;
    font-family: Noto Sans TC;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /* line-height: 140.62%; */
    letter-spacing: 0.05em;
    color: #3c4858;
    border-bottom: 1px solid #d0e0e2;
    padding-bottom: 5px;
    width: 100%;

    .titleText {
      width: 50%;
    }

    .totalUser {
      width: 50%;
      font-size: 16px;
      color: #6e6e6e;
      display: inline-grid;
      align-items: center;
      text-align: right;
    }
  }
  .controlButtons {
    width: 100%;
    display: flex;
  }
  .fieldName {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #d0e0e2;
    padding-top: 10px;
    padding-bottom: 5px;
    color: #004b62;
    .studentName {
      width: 80%;
    }
    .controls {
      width: 20%;
    }
  }
  .lists {
    padding-top: 10px;
    overflow-y: auto;
    height: 100%;

    .listItem {
      display: flex;
      border-bottom: 1px solid #d0e0e2;
      padding-bottom: 5px;

      .text {
        width: 80%;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }

      .raiseHand {
        max-width: 15%;
        text-align: right;
        cursor: pointer;

        .button {
          min-width: 20%;
        }
      }
    }
  }
}

.localVideo {
  position: relative;
  .volume {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}

.volumeMeter {
  position: absolute;
  bottom: 0;
  right: 0;
}

.closeDialog {
  width: 30%;
  padding: 5px 0;
  text-align: center;
  margin: 0 auto;
  background: #00828f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.listDialogBottom {
  width: 100%;
  display: flex;
  .bottomText {
    display: inline-grid;
    align-items: center;
    width: 70%;
  }
  .closeListDialog {
    width: 30%;
    padding: 5px 0;
    text-align: center;
    margin: 0 auto;
    background: #00828f;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }
}
.signal{

  position: absolute;
  left:10px;
  top:5px;
  width: 20px;

  &.signalLow{
    color: red;
  }
  &.signalHigh{
    color:green
  }
  &.signalMedium{
  color: yellow;
  }
}
