.chatRoom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #4D454A;
  height: 100%;

  .messagePanel {
    padding: 5px;
    border: 1px solid #ccc;
    overflow-y: scroll;
    background-color: #f0f1f2;
    max-height: 100%;
  }

  .controls {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    color: #000;
    justify-content: center;

    .messageInput {
      display: inline-flex;
      align-items: center;
      height: 100%;
      width: 80%;
      margin: 0 auto;
      & label{
        width: 100%;
        & input{
          width: 100%;
        }
      }
    }

    .sendButton {
      display: inline-flex;
      align-items: center;
      height: 100%;
      font-size: 12px;
      //margin-left: 10px;
      border-radius: 5px;
      padding: 5px;
      height: 30px;
      cursor: pointer;
    }
  }
}