@import '../../style/media';
.pageSearcher {
  flex-direction: column;
  height: 95%;
  padding-bottom: 70px;
  .thumbnailWrapper {
    &.horizontal{
      display: flex;
      align-items: flex-start;
      overflow-x: auto;
    }
    &.vertical {
      display: block;
      height: 95%;
      overflow-y: auto;
      .input {
        width: 100%;
      }
    }
    &.rightToLeft {
      direction: rtl;
    }

    &.doublePage {
      .thumbnailGroup {
        flex: 0 0 20%;
      }
    }
  }
  .thumbnailGroup {
    flex: 0 0 10%;
    overflow: hidden;
    .thumbnail {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      border: 3px solid transparent;
      cursor: pointer;
      overflow: hidden;
      img {
        width: 100%;
        //height: 100%;
        @include phone {
          width: 100%;
          height: 100%;
        }
      }
    }
    &.active .thumbnail{
      border-color: red;
    }
  }
}

.title {
  text-align: center;
  height: 25px;
  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }
}

.input {
  width: 33%;
  text-align: center;
  margin: 10px auto;
  label input {
    width: 100%;
    padding: 10px;
  }
}
