@import '../../style/media';

.readerView {
  display: flex;
  width: 100%;
  // height: calc(100vh - 9vh);
  height: 92vh;
  overflow: hidden;
  position: relative;

  &.left,
  &.right {
    height: 100vh;
  }

  @include tablet {
    height: 100%;
  }

  @include phone {
    height: 100%;
  }

  .bookContainer {
    width: 100%;
  }

  &.cursorDrag {
    &,
    canvas {
      cursor: url('/assets/img/cursor/mouse.png'), auto !important;
    }
  }

  &.cursorPainting {
    &,
    canvas {
      cursor: url('/assets/img/cursor/pen.png'), auto !important;
    }
  }

  &.cursorHighlighter {
    &,
    canvas {
      cursor: url('/assets/img/cursor/marker.png'), auto !important;
    }
  }

  &.cursorShape {
    &,
    canvas {
      cursor: url('/assets/img/cursor/shape.png'), auto !important;
    }
  }

  &.cursorLine {
    &,
    canvas {
      cursor: url('/assets/img/cursor/line.png'), auto !important;
    }
  }

  &.cursorDelete {
    &,
    canvas {
      cursor: url('/assets/img/cursor/eraser.png'), auto !important;
    }
  }

  &.cursorIntervalZoomIn {
    &,
    canvas {
      cursor: url('/assets/img/cursor/magnifier.png'), auto !important;
    }
  }

  &.cursorSelect {
    &,
    canvas {
      cursor: url('/assets/img/cursor/select.png'), auto !important;
    }
  }

  &.cursorMark {
    &,
    canvas {
      cursor: url('/assets/img/cursor/marktext.png'), auto;
    }

    text {
      cursor: text;
    }
  }

  .tableContentsMenu {
    overflow-y: hidden !important;
  }
}

.canvas {
  &.alignLeft {
    margin-right: auto;
  }

  &.alignRight {
    margin-left: auto;
  }
}

.ctrlButton {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  font-size: 55px;
  z-index: 3;

  &.left {
    left: 20px;
  }

  &.right {
    right: 20px;
  }
}

