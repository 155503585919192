@import '../../style/media';
.popover {
    position: absolute;
    max-width: 54vmin;
    // min-width: 21vmin;
    // min-width: 300px;
    // padding: 10px 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99999;

    &.oneBtn {
        min-width: 18vmin;
        .content {
            .btn {
                width: 98%;
                margin: 0 auto;
            }
        }
    }

    &.twoBtn {
        min-width: 36vmin;
        .content {
            .btn {
                width: 48%;
            }
        }
    }

    @include phone {
        width: 100%;
    }

    .closeBtn {
        position: absolute;
        top: 5px;
        right: 10px;
        color: #fff;
        cursor: pointer;
    }

    &.top {
        top: 110%;
        left: 50%;
        bottom: initial;
        right: initial;
        transform: translateX(-50%);
    }
    &.bottom {
        bottom: 110%;
        left: 50%;
        top: initial;
        right: initial;
        transform: translateX(-50%);
    }

    &.left {
        top: 50%;
        left: 110%;
        bottom: initial;
        right: initial;
        transform: translateY(-50%);
    }

    &.right {
        top: 50%;
        right: 110%;
        bottom: initial;
        left: initial;
        transform: translateY(-50%);
    }

    .title {
        // font-size: 15px;
        // font-weight: bold;
        // color: #fff;

        font-size: 3vmin;
        padding: 5px 0;
        font-weight: 700;
        text-align: center;
        color: #fff;
        height: 20%;
    }

    .content {
        overflow-x: hidden;
        overflow-y: auto;
        height: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 10px;
        margin-bottom: 10px;
        &::after {
            content: '';
            flex: 30%;
            width: 32%;
        }

        .btn {
            display: flex;
            // flex: 30%;
            width: 32%;
            // margin: 10px 15px;
            align-items: center;
            padding: 5px 10px;
            background: radial-gradient(
                circle at 50% -816%,
                #8bbee6 93%,
                #2b8cd1 50%
            );
            border-radius: 5px;
            cursor: pointer;
            font-size: 2vmin;
            margin: 5px 0;
        }

        .icon {
            width: 2vmin;
            height: 2vmin;
            color: white;
        }

        .text {
            font-size: 0.8em;
            margin: 5px 0px;
            font-weight: 700;
            padding: 0px 10px;
            color: #fff;
        }
    }
}
