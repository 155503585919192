@import '../../../style/media';

.WordLearningContentWrapper {
  width: 100%;
  flex: 1;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  direction: rtl;
}

.wordLearningContent {
  display: -webkit-box;
  height: 90%;

  & + div {
    margin-right: 9vmin;
  }

  .audio {
    margin-left: 30px;
    margin-top: 10px;
  }

  .imageBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 40vmin;
    max-width: 400px;
    overflow: hidden;
    margin: 0 20px;
    margin-top: 10px;

    .image {
      width: 40vmin;
      padding-bottom: 75%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        cursor: pointer;
      }
    }

    .prompt {
      font-size: 3vmin;
    }
  }

  .zhuyinLabel {
    margin-top: 10px;

    .bopomofo {
      background-color: #cbebfd;
      direction: ltr;

      @media (-webkit-min-device-pixel-ratio: 1.50) {
        display: block;
      }

      ruby {
        margin: .7rem 0;
        @include tablet {
          margin: .5rem 0;
        }
      }

      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.imageModal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100000;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 45px;

    img {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
    }

    .icon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 15px;
      right: 15px;
      width: 20px;
      height: 20px;
      font-size: 6vmin;
      cursor: pointer;
    }
  }
}