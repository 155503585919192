.pageToolDragZone {
    // z-index: 10000;
    &.show {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .pageTool {
        position: absolute;
        bottom: 1%;
        right: 3%;
        z-index: 10000;
        display: flex;
        align-items: flex-end;
        .pageToolButton {
            margin-left: 5px;
            button {
                pointer-events: none;
            }
            button:focus {
                background-color: initial;
            }
        }
        .container {
            position: relative;
            width: 20.5vmin;
            border-radius: 10px;
            background-color: rgba($color: #000000, $alpha: 0.7);
            display: none;
            overflow: hidden;
            margin: auto;
            &.show {
                display: block;
            }
            .closeBtn {
                color: #fff;
                cursor: pointer;
                display: flex;
                justify-content: flex-end;
                padding: 5px 10px 0px 0px;

                svg {
                    width: 4vmin;
                    height: 4vmin;
                }
            }

            .content {
                overflow-x: hidden;
                overflow-y: auto;
                height: 80%;
                .btn {
                    display: flex;
                    margin: 10px 15px;
                    align-items: center;
                    padding: 5px 10px;
                    background: radial-gradient(
                        circle at 50% -816%,
                        #8bbee6 93%,
                        #2b8cd1 50%
                    );
                    border-radius: 5px;
                    cursor: pointer;
                }

                .icon {
                    width: 2vmin;
                    height: 2vmin;
                    color: white;
                }

                .text {
                    font-size: 2vmin;
                    margin: 5px 0px;
                    font-weight: 700;
                    padding: 0px 10px;
                    color: #fff;
                }
            }
        }
    }
}
