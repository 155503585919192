@import '../../style/media';

.wordRadical {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 100000;

  .mainContent {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    padding: 10px;

    .content {
      max-width: 65%;
      flex: 1;
      height: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      text-align: right;
      padding-top: 10px;
      direction: rtl;

      &.noprogress {
        width: 100%;
      }

      img {
        height: 100%;
      }
    }

    .progress {
      width: 35%;
      display: flex;
      justify-content: center;
      align-items: center;

      .progressSlider {
        position: relative;
        width: 80%;
        border: 3px solid #ef3b79;
        border-radius: 3px;

        &.pointer {
          cursor: pointer;
        }

        .slider {
          .img {
            display: none;
            width: 100%;

            &.active {
              display: block;
            }
          }
        }

        .icon {
          position: absolute;
          bottom: 10px;
          right: 10px;
          font-size: 4vmin;
          color: #ef3b79;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding-left: 10px;
      padding-top: 10px;

      .audio {
        padding: 2vmin 0;
      }

      a {
        font-size: 4vmin;

        .videoIcon {
          margin-top: 10px;
          min-width: 60px;
          min-height: 60px;
          background-size: cover;
          @include phone {
            min-width: 40px;
            min-height: 40px;
            width: 40px;
            height: 40px;
          }
        }
      }

      .close {
        font-size: 7vmin;
        cursor: pointer;
      }
    }
  }

  .progressModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;

    .progressModalContent {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      background-image: url('/assets/img/wordGridBg.jpg');

      .itemList {
        display: flex;
        justify-content: center;
        align-items: center;

        .item {
          flex: 1;
          opacity: 0;
          text-align: center;
          margin-bottom: 20px;

          img {
            width: 80%;
          }

          .info {
            display: inline-block;
            font-size: 6vmin;
            background-color: #fe8bb3;
            color: #fff;
            padding: 5px 10px;
          }
        }

        .icon {
          opacity: 0;
          color: #fe8bb3;
          font-size: 8vmin;
        }

        .show {
          opacity: 1;
        }
      }

      .controls {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        //margin-top: 30px;

        > div {
          display: flex;
          width: 33.3333%;

          &.center {
            justify-content: center;

            span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 7vmin;
              height: 7vmin;
              background-color: #f19dbb;
              color: #fff;
              border-radius: 50%;
              font-size: 7vmin;
              margin: 0 10px;
            }
          }

          &.right {
            justify-content: flex-end;

            span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 7vmin;
              height: 7vmin;
              background-color: #f19dbb;
              color: #fff;
              border-radius: 50%;
              font-size: 5vmin;
              margin: 10px;
            }
          }
        }

        span {
          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
