@import '../../../../style/media';

.videoModalComponent {
  width: 100%;
  height: 100%;
}

.wordGridConentContainer {
  position: relative;
  display: flex;
  padding-right: 10px;
  background-size: cover;
  background-repeat: no-repeat;

  .setup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 10%;
    position: relative;
    z-index: 1;
    @media (-webkit-device-pixel-ratio: 1) {
      @media screen and (max-width: 1440px) {
        width: 15%;
      }
      @media screen and (max-width: 1280px) {
        width: 20%;
      }
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      @media screen and (max-width: 1280px) {
        width: 15%;
      }
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      @media screen and (max-width: 1440px) {
        width: 15%;
      }
    }
    .breakAnchor {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 10px;
      overflow-x: hidden;
    }

    .controls {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 1;

      &.notWordsSet {
        flex: 1;
      }

      .words {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;

        .word {
          width: calc(50% - 2px);
          padding: 10px;
          margin-bottom: 10px;
          margin-right: auto;
          margin-left: auto;
          background-color: #cdcdcd;
          font-size: 3vmin;
          font-weight: bold;
          border-radius: 5px;
          text-align: center;
        }
      }

      .extensionButtons {
        width: 100%;
        border-radius: 5px;

        .button {
          padding: 10px 0;
          text-align: center;
          margin: 5px 0;
          border-radius: 5px;
          background-color: #9fd3fc;
          box-shadow: 2px 2px 3px #aaa;
          cursor: pointer;
          font-size: 3vmin;

          &:hover {
            box-shadow: 0px 0px 3px #aaa;
          }

          &.active {
            border: 2px solid #9fd3fc;
            background-color: #fff;
            box-shadow: 0px 0px 3px #aaa;
          }

          &.disabled {
            color: #818181;
            background-color: #cdcdcd;
            cursor: not-allowed;
          }
        }
      }
    }

    // windows 顯示器 100%以上(含)
    @media (-webkit-min-device-pixel-ratio: 1) {
      .breakAnchor {
        .anchor {
          font-size: 3vmin;
        }
      }

      .controls {
        .extensionButtons {
          .button {
            padding: 5px 0;
            font-size: 2.5vmin;
          }
        }
      }

      @media screen and (max-width: 1280px) {
        .breakAnchor {
          .anchor {
            font-size: 2.5vmin;
          }
        }
      }
    }

    // windows 顯示器 125%以上(含)
    @media (-webkit-min-device-pixel-ratio: 1.25) {
      .breakAnchor {
        .anchor {
          font-size: 2.5vmin;
        }
      }

      .controls {
        .extensionButtons {
          .button {
            padding: 5px 0;
            font-size: 2.5vmin;
          }
        }
      }
    }

    // windows 顯示器 150%以上(含)
    @media (-webkit-min-device-pixel-ratio: 1.50) {
      .breakAnchor {
        .anchor {
          font-size: 2vmin;
        }
      }

      .controls {
        .extensionButtons {
          .button {
            padding: 5px 0;
            font-size: 3vmin;
          }
        }
      }
    }

    // windows 顯示器 175%以上(含)
    @media (-webkit-min-device-pixel-ratio: 1.75) {
      .breakAnchor {
        .anchor {
          font-size: 2vmin;
        }
      }

      .controls {
        .extensionButtons {
          .button {
            padding: 5px 0;
            font-size: 2.5vmin;
          }
        }
      }
    }
  }

  .meaningContentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    // width: calc(100% - 100px);
    width: 90%;
    height: 100%;
    padding-right: 10px;
    z-index: 10;
    @media (-webkit-device-pixel-ratio: 1) {
      @media screen and (max-width: 1440px) {
        width: 85%;
      }
      @media screen and (max-width: 1280px) {
        width: 80%;
      }
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      @media screen and (max-width: 1280px) {
        width: 85%;
      }
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      @media screen and (max-width: 1440px) {
        width: 85%;
      }
    }
    &.zhuyin {
      width: 100%;
    }

    .wordContentList {
      position: relative;
      z-index: 1;
    }
  }
}

