.bookmarkContainer{
    position: absolute;
    pointer-events: none;
    top:0;
    right:-100%;
    height: 100%;
    overflow: hidden;
    transition: .5s;
    z-index: 10000;
    &.show{
        right:0;
        transition: .5s;
    }
    .bookmark{
        pointer-events: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        padding: 10px 0;
        margin-bottom:5px;
        background-color: steelblue;
        color:#fff;
        writing-mode: vertical-lr;
        border-radius: 10px 0 0 10px;
        box-shadow: 1px 0px 5px;
        text-shadow: 0px 1px 1px #333;
        font-weight: bold;
        font-size: 3.2vmin;
        cursor: pointer;
        &.active{
            border: solid #fff;
            border-right-width: 0px;
        }
    }
}

