.wrapper {
  height: 100%;
  .showHidePannelBtn{
    position: absolute;
    left: -65px;
    top: 2%;
  }
}

.remoteVideoContainer {
  width: 50%;
  height: 100%;
  position: absolute;
  left: -50%;
  z-index: 10000;
  pointer-events: none;
  & > div {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: inline-block;
    & > div {
      max-width: 100%;
      video {
        position: initial;
      }
      & > div {
        left: 2.5rem;
        color: white;
        font-size: 0.3rem;
      }
    }
  }
}

.chatContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  .toolsControls {
    height: 20%;
    padding-top: 10px;
    .studentToolsControl {
      display: flex;
      button {
        width: 70px;
        height: 25px;
      }
    }

    .videoDeviceSelectComponent {
      width: 75%;
    }
  }

  .localVideoHide div video {
    display: none;
  }

  .localVideo {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 20%;
    margin-bottom: 5px;
    & > div {
      cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
    &.localVideoBG{
      background-color:black;
    }

    .fullVideoMessage {
      position: absolute;
      width: 60%;
      display: flex;
      justify-content: center;
      padding: 23%;
      &.fullVideoMessageHide {
        display: none;
      }
      img {
        position: absolute;
      }
    }
  }

  .localVideoSelect{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    & > div {
      //cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
  }

  .localAudio {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    & > div {
      //cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
  }

  .localSound {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    & > div {
      cursor: pointer;
      padding-right: 5px;
    }
    button {
      width: 25%;
      height: 25px;
    }
  }

  .emojiCounts {
    display: flex;
    //height: 3%;
    background-color: black;
    .emojiCount {
      display: flex;
      width: 20%;
      margin: 5px;
      & > div {
        margin: 0 auto;
        padding-top: 4px;
      }
      .countText{
        margin: 2px;
      }
    }

  }
  .chatRoomWrapper {
    height: 35%;
    &.tutoruser {
      height: 35%;
    }
  }
  .teacherVideoContainer {
    width: 75%;
    height: 20%;
    margin: 0 auto;
    .teacherVideo {
      position: relative;
      .volume {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }
  }
}


.localVideo {
  position: relative;
  .volume {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}

.volumeMeter{
  position: absolute;
  bottom: 0;
  right: 0;
}


.signal{

  position: absolute;
  left:10px;
  top:5px;
  width: 20px;

  &.signalLow{
    color: red;
  }
  &.signalHigh{
    color:green
  }
  &.signalMedium{
  color: yellow;
  }
}
