@import '../../../style/media';

.playbackRateControl {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: #FABAF1;
  border: 1px solid #FE8BB3;
  color: #323232;
  font-weight: bold;
  font-size: 2.5vmin;    
  cursor: pointer;
  @include tablet {
    font-size: 2.2vmin;
  }

  .controls {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
