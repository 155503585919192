@import '../../style/media';

.index {
  display: flex;
  justify-content: center;

  .container {
    width: 100%;
    box-shadow: 0px 0px 10px #ccc;
    padding: 20px 0;
  }

  .list-container {
    width: 100%;
    margin: 0;
    padding: 1%;
    list-style: none;
    // border: 0.2rem solid #ccc;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    // grid-gap: 20px;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    .list-item {
      width: 220px;
      height: 300px;
      margin: 1.6%;
      color: #fff;
      border: 0.1rem solid #ccc;
      border-radius: 0.2rem;
      background: linear-gradient(90deg, #fff 20%, #F4F4F4 80%);
      transition: all .3s ease;
      cursor: pointer;
      // grid-template-rows: repeat(auto-fit, minmax(2, 1fr));
      // grid-template-columns: 1fr;
      // grid-auto-rows: minmax(100, auto);

      .list-img {
        width: 100%;
        height: 85%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .list-title {
        height: 13.33%;
        line-height: 1.3;
        // padding: 0.7rem;
        // padding-top: 1rem;
        // padding-bottom: 0.3rem;
        color: #000;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      &:hover {
        transform: scale(1.05);
      }

      @include tablet {
        margin: 1.5%;
      }

      @include phone {
        margin: 3% auto;
      }
    }


    .default {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 8vmin;
      }

      .sub_title {
        margin: 5px 0;
        font-size: 4vmin;
      }

      .go_box {
        margin: 20px 0;
        width: 100%;
        display: flex;
        justify-content: center;

        .go_onemall {
          padding: 5px 10px;
          color: #fff;
          background-color: #ccc;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            color: #000;
            background-color: #f4f4f4;
          }
        }
      }
    }

  }

  a {
    text-decoration: none;
  }

  .version-info {
    text-align: right;
    padding-right: 10px;
  }

}


.dialogButton{
  border: solid;
  color: blue;
}

.progressContent{
  width: 25vh;
  .text{
    display: flex;
    align-items: center;
  }
  .progress{
    width:100%
  }
}

.loadingContent{
  width: 25vh;
  .text{
    text-align: center;
  }
  .loading{
    text-align: center;
    padding-top: 20px;
  }
}