@import '../../style/media';

header {
    width: 100%;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(20%, 20%, 40%, 0.5);
    position: fixed;
    z-index: 999;

    .container {
        width: 80%;
        display: flex;
        align-items: center;
        @include phone {
            width: 95%;
            padding-bottom: 10px;
        }
    }

    .top {
        display: flex;
        justify-content: center;
        padding-top: 10px;

        .logoBox {
            flex: 1;
            font-size: 0;
            @include phone {
                flex: 0 0 25%;
                width: 25%;
            }
            img {
                cursor: pointer;
                @include phone {
                    width: 100%;
                }
            }
        }

        .linkBox {
            flex: 2;
            display: flex;
            justify-content: center;
            @include phone {
                flex: 0 0 65%;
                width: 65%;
            }
            .link {
                margin: 0 20px;
                position: relative;
                font-size: 1rem;
                a {
                    text-decoration: none;
                    color: #000;

                    &:hover {
                        color: #dd7674;
                    }
                }

                &:last-child {
                    a {
                        color: #dd7674;
                    }

                    &:after {
                        content: '';
                        width: 100%;
                        height: 5px;
                        background-color: #dd7674;
                        position: absolute;
                        bottom: -10px;
                        right: 0;
                        left: 0;
                        margin: auto;
                    }
                }

                &:hover {
                    &:after {
                        content: '';
                        width: 100%;
                        height: 5px;
                        background-color: #dd7674;
                        position: absolute;
                        bottom: -10px;
                        right: 0;
                        left: 0;
                        margin: auto;
                    }
                }
            }
        }

        .funBox {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            // .search_box {
            //     width: 50%;
            //     .search {
            //         outline: 0;
            //         border-radius: 50px;
            //         border: 1px solid #eee;
            //         padding: 5px 10px;
            //     }
            // }
            @include phone {
                max-width: 10%;
            }
            .iconBox {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .book {
                    width: 30px;
                    height: 30px;
                    background-color: #eee;
                    border-radius: 50%;
                    margin: 0 5px;
                }
                .member {
                    width: 32px;
                    height: 32px;
                    background-color: #ccc;
                    border-radius: 50%;
                    margin: 0 5px;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
                .loginInfo {
                    padding: 5px 10px;
                    color: #fff;
                    background-color: #d42d26;
                    cursor: pointer;
                    transition: 0.5s;
                    white-space: nowrap;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    user-select: none;
                    &:hover {
                        @include desktop {
                            background-color: #f8bf01;
                            color: #414141;
                        }
                    }
                    @include phone {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        border-radius: 50%;
                        padding: 8px 11px;
                        max-width: 38px;
                        max-height: 38px;
                    }
                    span {
                        display: block;
                        @include phone {
                            display: none;
                        }
                        &.show {
                            @include phone {
                                display: block;
                            }
                        }
                    }
                    svg {
                        display: none;
                        @include phone {
                            display: inline;
                        }
                    }
                }
                .languageInfo {
                    padding: 0px 10px;
                    color: #000;
                    background-color: #fff;
                    border-style: groove;
                    cursor: pointer;
                    transition: 0.5s;
                    white-space: nowrap;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    user-select: none;
                    &:hover {
                        @include desktop {
                            background-color: #f8bf01;
                            color: #414141;
                        }
                    }
                    @include phone {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        // border-radius: 50%;
                        // padding: 8px 11px;
                        // max-width: 38px;
                        // max-height: 38px;
                    }
                    // span {
                    //     display: block;
                    //     @include phone {
                    //         display: none;
                    //     }
                    //     &.show {
                    //         @include phone {
                    //             display: block;
                    //         }
                    //     }
                    // }
                    // svg {
                    //     display: none;
                    //     @include phone {
                    //         display: inline;
                    //     }
                    // }
                }
            }
        }
    }
    .bottom {
        display: flex;
        justify-content: center;
        background-color: #f0f0f0;
        .list_box {
            width: 90%;
            display: flex;
            list-style: none;

            .list {
                cursor: pointer;
                a {
                    text-decoration: none;
                    color: #414141;
                    &:hover {
                        color: #aaa;
                    }
                }
            }
        }
        .sopping_car {
            width: 10%;
            .car {
                width: 20px;
                height: 20px;
                background-color: #ccc;
            }
        }
    }

    .version-info {
        display: flex;
        padding: 5px 10px 0;
        justify-content: flex-end;
        align-items: center;
        @include phone {
            display: none;
        }
    }
}

.mat {
    width: 100%;
    height: 105px;
}

.loginBox {
    .login {
        padding: 0.375rem 0.35rem;
        color: #fff;
        background-color: #ccc;
        border-radius: 0.25rem;
        line-height: 1.5;
        font-weight: 700;
        cursor: pointer;
        &.in {
            background-color: #28a745;
            &:hover {
                background-color: #218838;
            }
        }
        &.out {
            background-color: #545b62;
            &:hover {
                background-color: #5a6268;
            }
        }
    }
    .dropdownBox {
        position: relative;
        .dropdownMenu {
            min-width: 10rem;
            margin: 0.125rem 0 0;
            font-size: 1rem;
            color: #212529;
            text-align: center;
            list-style: none;
            background-color: #fff;
            border-radius: 0 0 0.25rem 0.25rem;
            position: absolute;
            top: 100%;
            @include phone {
                top: calc(100% + 10px);
                right: -10px;
                left: initial;
            }
            left: 0;
            z-index: 1000;
            max-height: 0;
            transition: 0.5s;
            overflow: hidden;
            .dropdownItem {
                padding: 13px 10px;
                font-weight: 400;
                color: #212529;
                cursor: pointer;
                &:hover {
                    background-color: #f8f9fa;
                }
                &.active {
                    display: none;
                    @include phone {
                        display: block;
                    }
                }
            }
            &.active {
                max-height: 300px;
                padding: 0.5rem;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
                    0 1px 2px rgba(0, 0, 0, 0.24);
            }
        }
    }
}
