.selectComponent {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 80%;
  color: black;
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140.62%;
  letter-spacing: 0.05em;

  .selectedText {
    background-color: #ffffff;
    padding: 2px 20px 2px 5px;
    border-bottom: 1px solid #000000;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .selectedText::after {
    content: "";
    position: absolute;
    right: 10px;
    top: calc(50% - 10px/2);
    border: 7px solid transparent;
    border-color: #4d454a transparent transparent transparent;
  }

  .selectedText.active::after {
    top: 8px;
    border-color: transparent transparent #fff transparent;
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .selectOptions {
    position: absolute;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    z-index: 10000;
    div {
      list-style-type: none;
      padding: 2px 10px 2px 5px;
      background: #ffffff;
      border-bottom: 1px solid #ffffff;
      cursor: pointer;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .videoDevice:hover {
      background-color: #ffd3c0;
      color: #000000;
    }
    .audioOutput:hover {
      background-color: #ffe4c4;
      color: #000000;
    }
  }
}
