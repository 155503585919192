
.visibleStatusComponent{
  margin: 10px 0;
  .title{
    font-weight: bold;
  }
  label{
    display: flex;
    align-items: center;
    + label{
      margin-left: 10px;
    }
  }
  .checkBoxGroup{
    display: flex;
    align-items: center;
  }
}

.clickableStatusComponent{
  margin: 10px 0;
}