@import '../../style/media';

.wordContentList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 20%;
  padding: 0 10px;

  &.zhuyin {
    min-height: calc(10rem + 16px);
    @include tablet {
      min-height: calc(9rem + 7.5px);
    }
  }

  .wordList {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  }

  .word {
    width: auto;
    height: auto;
    min-height: 4rem;
    max-height: calc(8rem + 10px);
    writing-mode: vertical-rl;
    background-color: #9fd3fc;
    box-shadow: 2px 2px 3px #aaa;
    border-radius: 5px;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    border: 3px solid #9fd3fc;
    font-size: 3vmin;
    @include tablet {
      font-size: 2.5vmin;
    }

    &.active {
      border: 3px solid #9fd3fc;
      background-color: #fff;
      box-shadow: 0px 0px 3px #aaa;
    }

    &.zhuyin {
      display: flex;
      flex-wrap: wrap;
      min-width: 2.5rem;
      height: 100%;
      max-height: calc(10rem + 7.5px);
      align-items: center;
      @include tablet {
        min-height: 4rem;
        max-height: calc(8rem + 7.5px);
      }

      .bopomofo {
        margin-bottom: 5px;
      }

      .word {
        padding: 0;
      }

      ruby {
        margin-bottom: 0.3rem;
      }
    }
  }

  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    background-color: #c5ddf6;
    font-size: 3vmin;
    cursor: pointer;

    &.disabled {
      background-color: #ccc;
    }
  }
}