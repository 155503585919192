.svgContent {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    g path {
        pointer-events: inherit;
    }

    g {
        pointer-events: none;
    }
}
.canvasSvgContent {
    z-index: 3;
}
