.wordMeaing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    display: flex;
    height: 100%;
    overflow-x: auto;
    direction: rtl;

    .item {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 10px 20px;

      .audio {
        width: 60px;
        outline: none;
      }

      .image {
        height: 100%;

        img {
          height: 100%;
        }
      }
    }
  }
}
