
  .anchorGroup{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .audio{
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      @media (-webkit-device-pixel-ratio: 1) {
        @media screen and (max-width: 1440px) {
          width: 30px;
          height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
        @media screen and (max-width: 1280px) {
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
        }
      }
      @media (-webkit-device-pixel-ratio: 1.25) {
        @media screen and (max-width: 1024px) {
          width: 30px;
          height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }
      @media (-webkit-device-pixel-ratio: 1.5) {
        @media screen and (max-width: 1440px) {
          width: 30px;
          height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }
    }
    .anchor {
      width: 100%;
      margin: 10px 0 10px 5px;
      background-color: #9fd3fc;
      border-radius: 5px;
      text-align: center;
      padding: 5px 2px;
      box-shadow: 2px 2px 3px #aaa;
      cursor: pointer;
      font-size: 2.8vmin;
      white-space: nowrap;
      
      @media (-webkit-device-pixel-ratio: 1.5) {
        @media screen and (max-width: 1920px) {
          margin: 5px 0 5px 5px;
        }
        @media screen and (max-width: 1440px) {
          margin: 2px 0 2px 5px;
          font-size: 2.5vmin;
        }
        @media screen and (max-width: 1280px) {
          margin: 2px 0 2px 5px;
        }
      }
      @media (-webkit-device-pixel-ratio: 1.25) {
        @media screen and (max-width: 1440px) {
          margin: 5px 0 5px 5px;
        }
        @media screen and (max-width: 1280px) {
          margin: 5px 0 5px 5px;
        }
        @media screen and (max-width: 1024px) {
          margin: 2px 0 2px 5px;
        }
      }
      @media (-webkit-device-pixel-ratio: 1) {
        @media screen and (max-width: 1440px) {
          margin: 5px 0 5px 5px;
        }
      }
      &:hover {
        box-shadow: 0px 0px 3px #aaa;
      }
    }
  }