.videoPlayer {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}

.video {
  flex: 1 0 75%;
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  text-align: center;
  padding-bottom: 70px;
  video {
    width: 100%;
    height: 100%;
    &::-webkit-media-controls {
      display: none !important;
    }
    &::-webkit-media-controls-enclosure {
      display: none !important;
    }
    &::-webkit-media-text-track-display {
      display: none;
    }
  }

  .controlBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 10px;
    opacity: 0;
    transition: 0.3s;

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      display: flex;
      align-items: center;
    }

    .processBar {
      left: 10px;
      width: calc(100% - 20px);
    }
  }

  &:hover .controlBar {
    opacity: 1;
    transition: 0.3s;
  }
}
