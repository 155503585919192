.svgContent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 11;

  &.hide {
    opacity: 0;
  }

  g, image {
    pointer-events: none;
    cursor: pointer;
  }

  & > svg {
    display: block;
  }

  &.down {
    z-index: 0;
  }

  .clickable {
    pointer-events: auto !important;
    * {
      pointer-events: auto !important;
    }
  }
}

.canvasObjectSavedSvgContentEnable {
  pointer-events: none;
}

.canvasObjectSavedSvgContentDisable {
  pointer-events: none;
}


